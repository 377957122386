
































 import { Notify } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import List from "@/components/ShareComponent/PullDownUp.vue"
 import MyComponent from "@/components/HandComponent/MyHandComponent.vue"
 import FooterBottom from "@/components/ShareComponent/ListBottom.vue"
 import Stroage from "@/util/Storage"
 import { HandAllUpData } from "@/Api/Hand/interfaceType"
 import { HandAllItem } from "@/Type/index"
 import { HandMyList } from "@/Api/Hand/index"
 import { HandStore } from "@/store/modules/Hand/Hand"
 import { Vue,Component, Watch } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";

 interface MyHandType<T=string>{
     loadingShow:boolean
     userId:T|number;
     token:T;
     StopUp:boolean;
     PullDown:boolean;
     UpDown:boolean
     ScrollNum:number;
     UpData:HandAllUpData
     List:Array<HandAllItem>

     initData():void;
     handlePullDown(bool?:boolean):void;
     handleUpDown(bool?:boolean):void;
     handleUpData(bool?:boolean):void;
     handleError(data:string):void;
 }

 @Component({ name:"MyHand",components:{ List,MyComponent,FooterBottom,HeadTop } })
 export default class MyHand extends ZoomPage implements MyHandType{
    loadingShow = true
    userId: string|number = ""
    token = ""
    StopUp = false
    PullDown = false
    UpDown = true
    ScrollNum = 0
    List:Array<HandAllItem> = []
    UpData:HandAllUpData = {
        pageNo:1,
        pageSize:15
    }

    mounted() {
        this.loadingShow = false
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.initData()
    }

    initData(){
        this.UpData = HandStore.GetHandMyUpData
        if( HandStore.GetHandMyList.length ){
            this.loadingShow = false
            this.List = HandStore.GetHandMyList
            this.ScrollNum = HandStore.GetHandScroll.MyScrollNum
        }else{
            this.handleUpData( true )
        }
    }

    handlePullDown(bool?:boolean){
        this.PullDown = false
        this.StopUp = false
        this.UpData.pageNo = 1
        HandMyList({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            this.PullDown = true
            if( res.message.code === "200" ){
                if( res.data.smProductList.length < this.UpData.pageSize ){
                    this.StopUp = true
                }
                this.List = res.data.smProductList
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleUpDown(bool?:boolean){
        if( this.StopUp )return;
        this.UpData.pageNo += 1
        this.handleUpData()
    }

    handleUpData(bool?:boolean){
        this.UpDown = true
        HandMyList({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            this.loadingShow = false
            this.UpDown = false
            if( res.message.code === "200" ){
                if( res.data.smProductList.length < this.UpData.pageSize ){
                    this.StopUp = true
                }
                if( bool ){
                    this.List = res.data.smProductList
                }else{
                    this.List = this.List.concat( res.data.smProductList )
                }
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }

    @Watch("UpData",{deep:true})
    ChangeUpData(newVal:HandAllUpData){
        HandStore.SetHandMyUpData( newVal )
    }

    @Watch("List")
    ChangeList(newVal:Array<any>){
        HandStore.SetHandMyList(newVal)
    }
}
