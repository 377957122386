
















































import { HandAllItem } from "@/Type/index"
import { HandStore } from "@/store/modules/Hand/Hand"
import { IndexStore } from "@/store/modules/Index"
import { Vue,Component, Prop, Watch } from "vue-property-decorator"

interface MyHandComponentType<T=string>{
    DefaultIcon :T;
    TestIcon :T;
    LeftImgBool:boolean
    LeftIcon:T;

    initData():void;
    handleToPath():void;
    handleFilterTime(str:string):any;
}

@Component({ name:"MyHandComponent" })
export default class MyHandComponent extends Vue implements MyHandComponentType{
    DefaultIcon = require("$icon/Basics/default.png");
    TestIcon = require("$icon/Hand/Test.png");
    LeftImgBool = true
    LeftIcon = ""

    mounted() {
       this.initData()   
    }

    initData(){
        if( this.GetData.pics.length ){
            let ImgList:Array<string> = this.GetData.pics.split(",")
            if( ImgList.length ){
                this.LeftImgBool = true
                this.LeftIcon = ImgList[0]
            }
        }else{
            this.LeftImgBool = false
        }
    }
    
    handleToPath(){
        let ScrollNum = HandStore.GetHandScroll
        let Num = IndexStore.GetScrollNum
        ScrollNum.MyScrollNum = Num
        HandStore.SetScrollNum( ScrollNum )
        this.$router.push({
            path:"/handSuc",
            query:{
                admin:"true",
                id:this.GetData.id as string
            }
        })
    }

    handleFilterTime(str:string){
        return str.split("T")[0]
    }

    get GetFilterState(){
        switch( Number( this.GetData.status ) ){
            case 0:
                return "出售中";
            case 1:
                return "已卖出";
            case 2:
                return "已取消";    
            default:
                return "啥也不是"
        }
    }

     @Prop(Object)
    data!:HandAllItem

    get GetData(){
        return this.data
    }

    @Watch("GetData")
    ChangeGetData(newVal:HandAllItem){
        this.initData()
    }
}
